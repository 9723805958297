import * as R from 'ramda'
import * as DateFns from 'date-fns'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Api from '@rushplay/api-client'
import * as Notifications from '@rushplay/notifications'

import * as Inventory from '../inventory'

import { InventoryCard as Component } from './inventory-card'

export const InventoryCard = R.compose(
  ReactRouter.withRouter,
  ReactRedux.connect(
    (state, props) => {
      const expiresAt = DateFns.parseISO(props.expiresAt)
      const activeFrom = DateFns.parseISO(props.activeFrom)

      const timeData = [
        {
          id: 'days',
          availableIn: DateFns.differenceInDays(activeFrom, props.dateNow),
          expiresIn: DateFns.differenceInDays(expiresAt, props.dateNow),
        },
        {
          id: 'hours',
          availableIn: DateFns.differenceInHours(activeFrom, props.dateNow),
          expiresIn: DateFns.differenceInHours(expiresAt, props.dateNow),
        },
        {
          id: 'minutes',
          availableIn: DateFns.differenceInMinutes(activeFrom, props.dateNow),
          expiresIn: DateFns.differenceInMinutes(expiresAt, props.dateNow),
        },
      ]

      return {
        timeLeft: props.active
          ? R.find(R.pathSatisfies(R.lt(0), ['expiresIn']), timeData)
          : R.find(R.pathSatisfies(R.lt(0), ['availableIn']), timeData),
      }
    },
    (dispatch, props) => {
      return {
        onActivate: () =>
          dispatch(
            Api.useItem(props.id, {
              success: res => {
                const item = res.value
                if (
                  R.includes(item.type, [
                    'Freespins',
                    'FeatureTriggers',
                    'FreeGamesVoucher',
                  ])
                ) {
                  props.history.push(
                    `/casino/games/${item.gameId}?mode=${
                      item.blitz ? 'blitz' : 'classic'
                    }&free_games=${item.freeGames}`
                  )
                  return Inventory.remove(item.id)
                } else if (R.equals(item.type, 'Freebet')) {
                  props.history.push('/sports')
                  return Inventory.remove(item.id)
                } else {
                  return Inventory.remove(item.id)
                }
              },
              failure: () =>
                Notifications.add({
                  message: 'errors.use-inventory-item.failed',
                  level: 'error',
                }),
              version: 1,
            })
          ),
      }
    }
  )
)(Component)
