import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Api from '@rushplay/api-client'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Analytics from '../analytics'
import * as Promotions from '../promotions'
import * as Inventory from '../inventory'
import * as Session from '../session'
import { PromotionCampaigns } from '../promotion-campaigns'
import { PromotionInventory } from '../promotion-inventory'
import { QueryDrawer } from '../query-drawer'
import { getSearchQuery } from '../get-search-query'
import { usePrev } from '../use-prev'
import { useSafeUpdateQuery } from '../use-safe-update-query'

const TabMenuItem = styled(ReactRouter.Link, {
  shouldForwardProp: Common.noneOf(['active']),
})`
  ${props =>
    css({
      p: 1,
      backgroundColor: props.active ? 'secondary-inventory-color' : '#e1e1e1',
      fontFamily: 'head',
      color: 'black',
      borderBottomRightRadius: props.right ? '15px' : '0px',
      borderTopRightRadius: props.right ? '15px' : '0px',
      borderBottomLeftRadius: props.left ? '15px' : '0px',
      borderTopLeftRadius: props.left ? '15px' : '0px',
    })};

  text-align: center;
  flex: 1;
  cursor: pointer;
  user-select: none;
`

export function PromotionsDrawer() {
  const i18n = I18n.useI18n()
  const location = ReactRouter.useLocation()
  const dispatch = ReactRedux.useDispatch()
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const query = getSearchQuery(location)
  const prevQuery = usePrev(query)
  const campaignsQuery = useSafeUpdateQuery({ promotions: 'campaigns' })
  const inventoryQuery = useSafeUpdateQuery({ promotions: 'inventory' })

  React.useEffect(() => {
    if (prevQuery.promotions !== query.promotions) {
      if (query.promotions) {
        // Fetch promotions and inventory on open
        dispatch([Promotions.fetch(), Inventory.fetch()])
        if (R.equals(query.promotions, 'campaigns')) {
          dispatch(
            Analytics.openPromotionMenu({
              path: location.pathname,
              title: document.title,
            })
          )
        }
      } else {
        // Mark as read when drawer closes
        dispatch(
          Api.markNotificationsAsSeen({
            success: () => [
              Promotions.markAllAsSeen(),
              Inventory.markAllAsSeen(),
            ],
            version: 1,
          })
        )
      }
    }
  }, [prevQuery, query])

  if (!authenticated) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="promotions"
      title={i18n.translate('promotion-notifications.title')}
    >
      <Common.Box
        px={0}
        pb={1}
        flexGrow="1"
        display="flex"
        flexDirection="column"
      >
        <Common.Box
          fontSize={2}
          color="g-text"
          display="flex"
          mx="-16px"
          mb={1}
          flexShrink="0"
        >
          <TabMenuItem
            left
            to={`?${campaignsQuery}`}
            active={R.equals(query.promotions, 'campaigns')}
          >
            {i18n.translate('promotions.tab.campaigns')}
          </TabMenuItem>
          <TabMenuItem
            right
            to={`?${inventoryQuery}`}
            active={R.equals(query.promotions, 'inventory')}
          >
            {i18n.translate('promotions.tab.inventory')}
          </TabMenuItem>
        </Common.Box>

        {R.equals(query.promotions, 'campaigns') && <PromotionCampaigns />}

        {R.equals(query.promotions, 'inventory') && <PromotionInventory />}
      </Common.Box>
    </QueryDrawer>
  )
}

// for @loadable/components
export default PromotionsDrawer
