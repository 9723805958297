import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from './create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./promotion-card-boom')),
  playboom24: loadable(() => import('./promotion-card-boom')),
  freedomcasino: loadable(() => import('./promotion-card-boom')),
  wettenlive: loadable(() => import('./promotion-card-boom')),
  casinosky: loadable(() => import('./promotion-card-boom')),
  casinoskyjp: loadable(() => import('./promotion-card-boom')),
  paradiseplay: loadable(() => import('./promotion-card-boom')),
}

export const PromotionCard = createBrandedComponent(
  'PromotionCard',
  BRANDED_COMPONENTS
)
