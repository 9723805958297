import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENT = {
  playboom: loadable(() => import('./empty-promotion-inventory-card-boom')),
  playboom24: loadable(() => import('./empty-promotion-inventory-card-boom')),
  freedomcasino: loadable(() =>
    import('./empty-promotion-inventory-card-boom')
  ),
  wettenlive: loadable(() =>
    import('./empty-promotion-inventory-card-wettenlive')
  ),
  casinosky: loadable(() =>
    import('./empty-promotion-inventory-card-casinosky')
  ),
  casinoskyjp: loadable(() =>
    import('./empty-promotion-inventory-card-casinosky')
  ),
  paradiseplay: loadable(() =>
    import('./empty-promotion-inventory-card-casinosky')
  ),
}

export const EmptyPromotionInventoryCard = createBrandedComponent(
  'EmptyPromotionInventoryCard',
  BRANDED_COMPONENT
)
